
import { Component, Vue } from "vue-property-decorator";
import { Action, Mutation, Getter } from "vuex-class";
import InvoiceShipmentList from "@/components/admin/list/InvoiceShipmentList.vue";
import InvoiceProductForm from "@/components/admin/forms/InvoiceProductForm.vue";
import { ISelectItem } from "@/types";
import { IUser } from "@/types/user";
import { IUserListRequest } from "@/types/user";
import {
  IInvoice,
  IInvoiceCreateRequest,
  IInvoiceUpdateRequest
} from "@/types/invoice";
import { InvoiceUpdateRequest } from "@/models/invoice";
import { InvoicePriceTag } from "@/models/invoice_price_tag";
import { ICustomer, ICustomerListRequest } from "@/types/customer";
import { IBranch, IBranchListRequest } from "@/types/branch";
import {
  IPaymentMethod,
  IPaymentMethodListRequest
} from "@/types/payment_method";
import { IPriceTag, IPriceTagListRequest } from "@/types/price_tag";
import { IShipment } from "@/types/shipment";
import { IProductShipment } from "@/types/product_shipment";
import { IProduct } from "@/types/product";
import { ISupplier, ISupplierListRequest } from "@/types/supplier";
import { ITax, ITaxListRequest } from "@/types/tax";
import {
  IExceptPaymentTerm,
  IExceptPaymentTermRequest,
  IPaymentTerm,
  IPaymentTermRequest
} from "@/types/payment_term";
import { ExceptPaymentTermRequest } from "@/models/payment_term";

@Component({
  components: { InvoiceShipmentList, InvoiceProductForm }
})
export default class extends Vue {
  //invoice
  @Action("invoice/adminGet")
  public getInvoice!: (invoice_id: number) => void;

  @Action("invoice/adminUpdate")
  public update!: (data: {
    invoice_id: number;
    params: IInvoiceUpdateRequest;
  }) => boolean;

  @Action("invoice/adminDelete")
  public deleteInvoice!: (invoice_id: number) => boolean;

  @Action("invoice/adminCreate")
  public create!: (params: IInvoiceCreateRequest) => boolean;

  @Getter("invoice/single")
  public invoice!: IInvoice;

  @Mutation("invoice/clear")
  public clearInvoice!: () => void;

  //customer
  @Action("customer/adminGetList")
  public getCustomers!: (params: ICustomerListRequest) => boolean;

  @Action("customer/adminGet")
  public getCustomer!: (customer_id: number) => void;

  @Getter("customer/find")
  public findCustomer!: (id: number) => ICustomer;

  @Getter("customer/selectItem")
  public customerList!: ISelectItem[];

  @Getter("customer/single")
  public customer!: ICustomer;

  @Mutation("customer/clear")
  public clearCustomer!: () => void;

  //branch
  @Action("branch/adminGetList")
  public getBranches!: (params: IBranchListRequest) => boolean;

  @Getter("branch/find")
  public findBranch!: (id: number) => IBranch;

  @Getter("branch/selectItem")
  public branchList!: ISelectItem[];

  @Mutation("branch/clear")
  public clearBranch!: () => void;

  //payment_method
  @Action("payment_method/adminGetList")
  public getPaymentMethods!: (request: IPaymentMethodListRequest) => boolean;

  @Getter("payment_method/find")
  public findPaymentMethod!: (id: number) => IPaymentMethod;

  @Getter("payment_method/selectItem")
  public paymentMethodList!: ISelectItem[];

  @Mutation("payment_method/clear")
  public clearPaymentMethod!: () => void;

  //payment_term
  @Action("payment_term/adminGetList")
  public getPaymentTerms!: (params: IPaymentTermRequest) => boolean;

  @Action("payment_term/adminExpectPayment")
  public getExpectPayment!: (params: IExceptPaymentTermRequest) => boolean;

  @Getter("payment_term/except_payment_term")
  public except_payment_term!: IExceptPaymentTerm;

  @Getter("payment_term/list")
  public paymentTermList!: IPaymentTerm[];

  @Getter("payment_term/find")
  public findPaymentTerm!: (id: number) => IPaymentTerm;

  @Mutation("payment_term/clear")
  public clearPaymentTerm!: () => void;

  //price_tag
  @Action("price_tag/adminGetList")
  public getPriceTags!: (request: IPriceTagListRequest) => boolean;

  @Getter("price_tag/find")
  public findPriceTag!: (id: number) => IPriceTag;

  @Getter("price_tag/selectItem")
  public priceTagList!: ISelectItem[];

  @Mutation("price_tag/clear")
  public clearPriceTag!: () => void;

  //supplier
  @Action("supplier/adminGetList")
  public getSuppliers!: (params: ISupplierListRequest) => boolean;

  @Getter("supplier/find")
  public findSupplier!: (id: number) => ISupplier;

  @Mutation("supplier/clear")
  public clearSupplier!: () => void;

  //product
  @Getter("product/find")
  public findProduct!: (id: number) => IProduct;

  //Tax
  @Action("tax/adminGetList")
  public getTaxes!: (params: ITaxListRequest) => boolean;

  @Getter("tax/find")
  public findTax!: (id: number) => ITax;

  @Getter("tax/selectItem")
  public selectTaxList!: ISelectItem[];

  @Mutation("tax/clear")
  public clearTax!: () => void;

  //authUser
  @Getter("auth/me")
  public user!: IUser;

  //user
  @Action("user/adminGetList")
  public getUsers!: (params: IUserListRequest) => boolean;

  @Getter("user/find")
  public findUser!: (id: number) => IUser;

  @Getter("user/selectItem")
  public userList!: ISelectItem[];

  @Mutation("user/clear")
  public clearUser!: () => void;

  //パラメータ定義
  public params: IInvoiceUpdateRequest = new InvoiceUpdateRequest();

  //変数定義
  public payment_status = Vue.prototype.$paymentStatus;
  public invoice_id = 0;
  public tax_name_select: string | null = null;
  public product_shipment_list: IProductShipment[] = [];
  public edit_shipment_check_list: IShipment[] = [];
  public is_minus: number[] = [];
  public payment_term: string | null = "ー";
  public is_regist_payment_term = true;
  public is_calc_payment_date = true;
  public product_disp_switch = true;
  public submit_dialog = false;
  public destroy_dialog = false;
  public valid = true;
  public child_valid = false;
  public lazy = false;
  public disp = false;
  public menu = {
    invoice_date: false,
    payment_date: false,
    paid_date: false
  };

  //請求ステータス
  public payment_status_list = [
    { value: this.payment_status.uncollected, text: "未回収" },
    { value: this.payment_status.collected, text: "回収済" },
    { value: this.payment_status.unnecessary_collect, text: "回収不要" }
  ];

  //ルール設定
  public rules = {
    payment_status: [(v: number) => !!v || "請求ステータスは必須です"],
    invoice_date: [(v: string) => !!v || "請求書発行日は必須です"],
    paid_date: [(v: string) => !!v || "入金認定日は必須です"],
    tax_name: [(v: string) => !!v || "税目は必須です"],
    tax_rate: [
      (v: number) => !(!v && v !== 0) || "税率は必須です",
      (v: number) => v >= 0 || "0未満は入力できません",
      (v: string) =>
        /^([1-9]\d*|0)(\.\d{1,2})?$/.test(v) ||
        "小数点以下2桁までで入力してください"
    ],
    customer_id: [(v: number) => !!v || "顧客は必須です"],
    total: [(v: number) => v >= 0 || "0未満は入力できません"],
    sales_staff: [(v: number) => !!v || "テクネ担当者は必須です"]
  };

  //--------
  // コンポーネント作成時実行
  public async created() {
    this.invoice_id = Number(this.$route.params.invoice_id);
    this.params.user_id = this.user.id;
    this.clearInvoice();
    this.clearCustomer();
    this.clearBranch();
    this.clearPaymentMethod();
    this.clearPaymentTerm();
    this.clearPriceTag();
    this.clearSupplier();
    this.clearTax();
    this.clearUser();

    await Promise.all([
      this.getInvoice(this.invoice_id),
      this.getCustomers({ per_page: 0 }),
      this.getPaymentMethods({ per_page: 0 }),
      this.getPriceTags({ per_page: 0 }),
      this.getSuppliers({ per_page: 0 }),
      this.getTaxes({ per_page: 0 })
    ]);

    this.setDefault();

    this.disp = true;

    this.$nextTick(function () {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
    });
  }

  //--------
  // 更新時デフォルト値をセットする
  private async setDefault() {
    this.params.createFromInvoice(this.invoice);

    this.tax_name_select = this.invoice.tax_name;

    this.params.price_tag.forEach((price_tag, index) => {
      this.params.price_tag[index].price = Number(
        Number(this.params.price_tag[index].price).toFixed(2)
      );
      this.params.price_tag[index].actual_price = Number(
        Number(this.params.price_tag[index].actual_price).toFixed(2)
      );
      this.is_minus[index] = price_tag.price_tag_id
        ? this.findPriceTag(price_tag.price_tag_id).is_minus
        : 0;
    });

    await Promise.all([
      this.getCustomer(this.params.customer_id),
      this.getBranches({
        customer_id: this.params.customer_id,
        per_page: 0
      }),
      this.getPaymentTerms({ customer_id: this.params.customer_id })
    ]);

    if (this.paymentTermList.length) {
      this.modifyPaymentTermList();
    }

    if (!this.paymentTermList.length) {
      this.is_regist_payment_term = false;
    }

    if (this.params.branch_id) {
      await this.getUsers({ branch_id: this.params.branch_id });
      if (this.userList.length == 0) {
        await this.getUsers({ customer_id: this.params.customer_id });
      }
    }

    if (!this.params.branch_id) {
      await this.getUsers({ customer_id: this.params.customer_id });
    }

    if (this.params.invoice_product.length) {
      this.product_disp_switch = false;
    } else {
      this.edit_shipment_check_list = this.invoice.shipment;
    }
  }

  // 登録確認画面
  public submitConfirm() {
    this.submit_dialog = true;
  }

  //--------
  // 確定ボタンが押された際の挙動
  public async submit() {
    this.submit_dialog = false;
    let result = false;

    //諸経費配列の整理
    if (!this.params.price_tag.some(item => item.price_tag_id > 0)) {
      this.params.price_tag.splice(0);
    } else {
      this.params.price_tag = this.params.price_tag.filter(item => {
        return item.price_tag_id != 0;
      });
    }

    //請求書更新
    result = await this.update({
      invoice_id: this.invoice_id,
      params: this.params
    });

    if (result) {
      this.$router.go(-1);
    }
  }

  //--------
  // 削除確認画面
  public destroyConfirm() {
    this.destroy_dialog = true;
  }

  //--------
  // 削除実行
  public async destroy() {
    this.destroy_dialog = false;
    if (await this.deleteInvoice(this.invoice_id)) {
      this.$router.push(`/admin/invoice/list`);
    }
  }

  //顧客が選択された際の挙動
  public async customerSelected() {
    this.clearBranch();
    this.clearUser();
    this.params.branch_id = null;
    this.params.sales_staff_id = null;
    this.payment_term = "ー";
    this.customer.cutoff_day = null;
    this.is_regist_payment_term = true;
    this.is_calc_payment_date = true;
    this.params.payment_date = null;
    this.params.payment_method.splice(0);
    if (this.params.customer_id) {
      await Promise.all([
        this.getCustomer(this.params.customer_id),
        this.getBranches({
          customer_id: this.params.customer_id,
          per_page: 0
        }),
        this.getUsers({ customer_id: this.params.customer_id }),
        this.getPaymentTerms({ customer_id: this.params.customer_id })
      ]);

      if (this.paymentTermList.length) {
        this.modifyPaymentTermList();
      }

      if (!this.paymentTermList.length) {
        this.is_regist_payment_term = false;
      }

      if (this.userList.length == 1) {
        this.params.sales_staff_id = Number(this.userList[0].value);
      }
    }
  }

  //支店が選択された際の挙動
  public async branchSelected() {
    this.clearUser();
    this.params.sales_staff_id = null;
    if (this.params.branch_id) {
      await this.getUsers({ branch_id: this.params.branch_id });
      if (this.userList.length == 0) {
        await this.getUsers({ customer_id: this.params.customer_id });
      }
      if (this.userList.length == 1)
        this.params.sales_staff_id = Number(this.userList[0].value);
    }

    if (!this.params.branch_id) {
      await this.getUsers({ customer_id: this.params.customer_id });
      if (this.userList.length == 1)
        this.params.sales_staff_id = Number(this.userList[0].value);
    }
  }

  //税目が選択された際の挙動
  public async taxSelected(tax_index: number) {
    if (tax_index) {
      const findTax = this.findTax(tax_index);
      this.params.tax_name = findTax.name;
      this.params.tax_rate = findTax.tax_rate
        ? Number(findTax.tax_rate.toFixed(2))
        : 0;
    } else {
      this.params.tax_name = "";
      this.params.tax_rate = 0;
    }
  }

  //諸経費追加
  public appendPriceTagList() {
    this.params.price_tag.push(new InvoicePriceTag());
    this.$nextTick(function () {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
    });
  }

  //諸経費削除
  public deletePriceTagList(index: number) {
    this.is_minus.splice(index, 1);
    this.params.price_tag.splice(index, 1);
  }

  //出荷一覧の選択項目取得
  public receivedShipmentIds(shipment_check_list: IShipment[]) {
    this.arrangeShipmentCheckList(shipment_check_list);
    this.params.shipment_ids = shipment_check_list.map(shipment => {
      return shipment.id;
    });
  }

  //shipment_check_listの整形
  public arrangeShipmentCheckList(shipment_check_list: IShipment[]) {
    const product_shipments: IProductShipment[] = [];
    shipment_check_list.forEach(shipment => {
      shipment.product_shipment.forEach(product_shipment => {
        product_shipments.push(product_shipment);
      });
    });
    this.product_shipment_list = product_shipments;
  }

  //請求金額計算
  public get total() {
    let total = 0;
    if (this.product_disp_switch) {
      this.product_shipment_list.forEach(product_shipment => {
        total +=
          product_shipment.quantity * product_shipment.selling_unit_price;
      });
    }
    if (!this.product_disp_switch) {
      this.params.invoice_product.forEach(invoice_product => {
        total +=
          (invoice_product.quantity || 0) *
          (invoice_product.selling_unit_price || 0);
      });
    }

    this.params.price_tag.forEach((price_tag, index) => {
      this.is_minus[index]
        ? (total -= Number(price_tag.price))
        : (total += Number(price_tag.price));
    });

    return total;
  }

  // 商品コンポーネント切り替え
  public productComponentSwitch() {
    this.product_disp_switch = !this.product_disp_switch;
    if (!this.product_disp_switch) {
      this.params.shipment_ids.splice(0);
      this.product_shipment_list.splice(0);
    }
    if (this.product_disp_switch) {
      this.params.invoice_product.splice(0);
    }
  }

  //priceバリデーション
  public priceRule(v: number) {
    if (v < 0) {
      return "0以下は入力できません";
    }
    if (!/^([1-9]\d*|0)(\.\d{1,2})?$/.test(String(v))) {
      return "小数点以下2桁までで入力してください";
    }

    return true;
  }

  //price_tag_idバリデーション
  public prriceTagRule(v: number) {
    const countSamePriceTagId = this.params.price_tag.filter(
      price_tag => price_tag.price_tag_id == v
    ).length;
    if (countSamePriceTagId >= 2) {
      return "同じ諸経費名は選択できません";
    }

    return true;
  }

  //全バリデーションチェック
  public validCheck() {
    this.$nextTick(function () {
      (this.$refs.form as Vue & { validate: () => boolean }).validate();
    });
  }

  //price_tagのis_minusチェック
  public isMinusCheck(price_tag_id: number, index: number) {
    this.is_minus[index] = price_tag_id
      ? this.findPriceTag(price_tag_id).is_minus
      : 0;
  }

  //payment_date自動算出
  public async calcPaymentDate() {
    const params: IExceptPaymentTermRequest = new ExceptPaymentTermRequest();
    params.customer_id = this.params.customer_id;
    params.invoice_date = this.params.invoice_date;
    params.total_amount = this.total * (this.params.tax_rate / 100 + 1);

    this.params.payment_method.splice(0);

    await this.getExpectPayment(params);

    this.is_calc_payment_date = this.except_payment_term.payment_date
      ? true
      : false;

    if (
      !this.params.payment_method.includes(
        this.except_payment_term.payment_method_id
      )
    ) {
      this.params.payment_method.push(
        this.except_payment_term.payment_method_id
      );
    }

    this.params.payment_date = this.except_payment_term.payment_date;
  }

  //支払条件をtextarea用に整形
  public modifyPaymentTermList() {
    this.payment_term = "";

    this.paymentTermList.forEach((paymentTerm, index) => {
      const amount_range = paymentTerm.is_under_amount
        ? "未満"
        : paymentTerm.is_under_amount == null
        ? ""
        : "以上";

      const payment_month =
        paymentTerm.payment_month == 1
          ? "翌月"
          : paymentTerm.payment_month == 2
          ? "翌々月"
          : "ー";

      if (this.payment_term == null) return;

      this.payment_term = this.payment_term.concat(
        "・金額上下限：" +
          (paymentTerm.amount
            ? "￥" + paymentTerm.amount.toLocaleString() + amount_range
            : "ー") +
          " / 支払月：" +
          payment_month +
          " / 支払日：" +
          (paymentTerm.payment_day
            ? paymentTerm.payment_day == 99
              ? "月末"
              : paymentTerm.payment_day + "日"
            : "ー") +
          " / 加算日：" +
          (paymentTerm.add_day ? paymentTerm.add_day + "日" : "ー") +
          " / その他条件：" +
          (paymentTerm.other_terms || "ー") +
          (index != this.paymentTermList.length - 1 ? "\n" : "")
      );
    });
  }
}
